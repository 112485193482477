import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="edit-clinician"
export default class extends Controller {
  handleChange(e){
    if (e.target.value === 'fax') {
      $('#phone_number_update').parent().show()
      $('#phone_number_update').prop('required', true)
    } else {
      $('#phone_number_update').parent().hide()
      $('#phone_number_update').prop('required', false)
    }
  }
}
