import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// Connects to data-controller="turbo"
export default class extends Controller {
  initialize() {
    this.element.classList.remove("disabled-turbo-link")
    this.element.setAttribute('data-action', 'click->turbo#click')
  }

  click(e) {
    e.preventDefault()

    // Allow modals to open instead of restream
    // set data-target to the ID of the modal you wish to reference
    // use data-target-type on the modal and link to specify a modifier to the modal the modal needs to have in order to open
    if (e.target.dataset.target) {
      if (e.target.dataset.targetType) {
        if (($(`#${e.target.dataset.target}`).data('type') === e.target.dataset.targetType)) {
          $(`#${e.target.dataset.target}`).modal('show')
          return
        }
      } else if ($(`#${e.target.dataset.target}`).length) {
        $(`#${e.target.dataset.target}`).modal('show')
        return
      }
    }

    // allows link to open existing modal before streaming data
    if (e.currentTarget.dataset.modal) {
      $(`#${e.currentTarget.dataset.modal}`).modal('show')
    }

    this.url = this.element.getAttribute('href')
    fetch(this.url, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      }
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }
}
